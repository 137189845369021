
<template>
  <div class="lay_container">
    <Header></Header>
    <div class="cont_m" @mousedown="handleonmousedown($event)">
      <div class="max_cont_width flex_box">
        <div class="left_menu">
          <ul class="de_menu_list">
            <li class="de_menu_item" v-for="(item, index) in menuTitleList" :key="index">
              <span class="de_menu_item_a" :class="linkActive == index&&$route.name === item.title.replace(/\s/g, '') ? 'router-link-active' : ''" @click="handlFristMenu(index, item,-1), (linkActive = index)">
                                    <div class="title flex_center" :class="linkActive == index && linkActive != -1 ? 'subOn' : ''">
                                        <!-- <img class="menu_ic" :src="item.img1" v-if="linkActive == index" />
                                        <img class="menu_ic" :src="item.img" v-else /> -->
                                       {{ item.title }}
                                    </div>
                                </span>
              <!-- <router-link class="de_menu_item_a" :to="item.url" @click.native="handlFristMenu(index, item), (linkActive = index)">
                <div class="title" :class="'ic_' + index">
                  {{ item.title }}
                </div>
              </router-link> -->
            </li>
          </ul>
        </div>
        <div class="right_box">
          <div class="flex_center_between_box">
            <div class="c_detail_top_left line_2">
              <div class="c_d_title" v-show="!indexIsTranslate">{{ obj.name }}</div>
              <div class="c_d_title" v-show="indexIsTranslate">{{ obj.nameCn }}</div>
              <div class="flex_between_box">
                <!-- <div class="c_d_tag">
                  <el-tag v-for="(item, index) in obj.tags" :key="index">{{ item }}</el-tag>
                </div> -->
                <!-- <div class="last_date">Last Update:{{ obj.lastDate }}</div> -->
              </div>
            </div>
            <div class="c_detail_top_right">
              <div class="c_d_buttom flex_box">
                <el-popover placement="bottom" trigger="hover" width="240" popper-class="pop_link">
                  <button disabled  class="btn_blue flex_center" slot="reference">
                    <img src="../../assets/img/detail/btn_ic1.png" />Compare</button>
                  <ul class="compare_box">
                    <li><router-link :to="{ path: '/company/comparecompanys', query: {id3a: companyBase.aaaId, companyCountry: companyBase.companyCountry,companyNameEn:companyBase.nameEn,companyNameCn:companyBase.name} }" >Company</router-link></li>
                    <li><router-link :to="{ path: '/company/comparecountry', query: {id3a: companyBase.aaaId, companyCountry: companyBase.companyCountry} }" >Country</router-link></li>
                  </ul>
                </el-popover>
                <el-tooltip :content="toolPop" :visible-arrow="false" placement="top" effect="light">
                  <button class="btn_gray" v-show="production">In Production...</button>
                </el-tooltip>
                <button disabled  class="btn_blue flex_center" style="background-color: dimgray;">
                  <img v-show="updateDisabled" src="../../assets/img/detail/btn_ic8.png" />
                  <img v-show="!updateDisabled" src="../../assets/img/detail/btn_ic2.png" />
                  Update
                </button>
                <button class="btn_blue flex_center" @click="showReportIndex = true"><img src="../../assets/img/detail/btn_ic3.png" />Report Error</button>
              </div>
              <div class="c_d_icbtn">
                <el-tooltip class="item" popper-class="tool_tip" :visible-arrow="false" effect="light" content="Translate" placement="bottom">
                  <el-button class="tool_blue"  @click="fanyi()">
                    <img src="../../assets/img/detail/head_ic1.png" />
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" popper-class="tool_tip tool_tip2" effect="light" :visible-arrow="false" content="Download Online Report" placement="bottom">
                  <el-button disabled class="btn_blue " style="background-color: dimgray;"    @click="getPDFFile('onlineReport')">
                    <img src="../../assets/img/detail/head_ic3.png" />
                  </el-button>
                </el-tooltip>
                <!-- <el-tooltip class="item" v-if="companyBase.isFavorite" popper-class="tool_tip tool_tip3" :visible-arrow="false" effect="light" content="Add to My List" placement="bottom">
                  <el-button class="table_oper on"><img src="../../assets/img/search/table_ic1.png"/></el-button>
                </el-tooltip>
                <el-tooltip class="item" v-else popper-class="tool_tip tool_tip3" :visible-arrow="false" effect="light" content="Add to My List" placement="bottom">
                  <el-button class="table_oper"><img src="../../assets/img/search/table_ic2.png" @click="addToList"/></el-button>
                </el-tooltip> -->
                <el-tooltip class="item" popper-class="tool_tip tool_tip3" :visible-arrow="false" effect="light" content="Add to My List" placement="bottom">
                    <el-button :class="isfalow==true?'tool_add':''"><img src="../../assets/img/detail/head_ic2.png" @click="addToList" /></el-button>
                </el-tooltip>
              </div>
            </div>
          </div>

          <router-view  @updateSubLink="updateSubLinkParent" :showTranslate="showTranslate"></router-view>
        </div> 
      </div>
    </div>
    <Footer></Footer>
    <Collection :fromPage="fromPage" @childCloseDialog="closeCollageDialog(arguments)" :addList.sync="addList" :addPop="addPopIndex" :collecObj="collecObj"></Collection>

<!-- 报错弹框 --> 
<Reporterror :userrep="userrep" :showReport="showReportIndex" @closeReportDialog="closeDialog($event)" :powerStatus="powerToReportErrorStatus"></Reporterror>
  </div>
</template>
<script>
import Header from '@/components/header'

import Footer from '@/components/footer'
import { selectGroup } from '@/api/searchApi.js'
import Reporterror from '@/components/userreporterror'
import Collection from '@/components/collection'
import { getPdfAddress, getPdfPurchase } from '@/api/userApi.js';
import bus from '@/components/bus';
import { getCompanyDetailMenuList, getUpdateDataCompare , ordersAdd,getIsCollect} from '@/api/companyApi.js'
export default {
  components: { Header, Footer ,Reporterror,Collection},
  data() {
    return {
     
      menuTitleList: [
    
      ],
      userrep:true,
      companyBase: {
                name: '',
                nameEn: '',
                aaaId: '',
                companyCountry: '',
                lastUpdate: '',
                lableActive: '',
                lableCode: '',
                lableDate: '',
            },
      currentIndex: '0',
      fromPage: '',
      index: '',
      linkActive: sessionStorage.getItem('menuItem') ? sessionStorage.getItem('menuItem') : 0,
      obj: { name: 'VINCENT CAO',nameCn:'', tags: ['Active', 'MNC'], lastDate: '2020' },
      indexIsTranslate: false,
      searchName: '',
      companyCountry: '',
      toolPop: 'It normally takes 2-4 days to deliver,this updated rerpot.',
      production: false,
      updateDisabled: false,
      showReportIndex: false,
      showTranslate:false,
      addPopIndex:false,
      collecObj:{},
      addList:[],
      powerToPage: {}, //分发页面权限
            powerToReportErrorStatus: false,
            powerCurStatus: {},
      token: localStorage.getItem('token'),
      // companyBase:{}
      isfalow:false,
    }
  },
  computed: {},

  created() {
    this.getMenuList()
    this.updateSubLinkParent()
    // this.handlFristMenu()
  },
  mounted() {
    // window.addEventListener('setItem', () => {
    //         this.token = sessionStorage.getItem('token');
    //         if (this.token) {this.getMenuList();}
    //     });
    this.getContentData();
   
    
  },
  //页面销毁
    //页面销毁
    beforeDestroy() {
        window.removeEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
        // sessionStorage.removeItem('menuItem')
        // window.removeEventListener('setItem', this.listenMenuIndexChange);
    },
    destroyed(){
        bus.$off('handleShowLogin');
        sessionStorage.removeItem('menuItem');
    },
  methods: {
    updateSubLinkParent(val){
            // this.subLinkActive=val
            console.log('updateSubLinkParent---',val);
           console.log(this.$route.name=='Background');
            if(this.$route.name=='Background'){
                this.linkActive=0
                this.subLinkActive=''
            }
        },
    handleonmousedown(e) {
            // 处理登录+模块查看功能：
            // console.log('path', e.path);
            // console.log(111);
            const token = localStorage.getItem('token');
            // console.log(token);
            if (!token) {
                //未登录 允许点击接口返回的指定power=true模块
                if (!(e.target?.id === 'fan_yi' || e.target?._prevClass === 'title flex_center subOn' )) {
                    // 弹出登录窗口
                    // bus.$emit('handleShowLogin', true); //是否显示登录
                }
            } 
        },
    fanyi(){
      this.showTranslate = !this.showTranslate
      console.log(this.showTranslate);
    },
    // 跟新
    async getContentData() {
      this.$nextTick(() => {
        const company = JSON.parse(sessionStorage.getItem('User'))
      // console.log(company);
      // console.log(this.$route.query);
      console.log(company==null);
      // const base = JSON.parse(sessionStorage.getItem('base'))?JSON.parse(sessionStorage.getItem('base')):this.companyBase
      this.companyBase = {
    
        name: company==null?this.$route.query.name:company.pname,
        nameEn:  company==null?this.$route.query.name:company.pnameEn,
        aaaId: company==null?this.$route.query.pid:company.pid,
        companyCountry :company==null?this.$route.query.companyCountry:company.countryCode,
        isFavorite:company==null?true:company.isFavorite
        // lastUpdate:base.base?base.base.lastUpdate:''
      }
                // this.hasPdfPurchase();
            });
      // const companyCountry = this.$route.query.companyCountry
    
      this.getCollData()
      // console.log(isFavorite);
    },
    tab(index) {
      console.log(index)
      this.currentIndex = index
      console.log(this.currentIndex)
    },

    btnTranslate() {
      this.indexIsTranslate = !this.indexIsTranslate
    },
        //更新
        async updateCompany() {
      let params = { companyName: this.$route.query.name, companyId: this.$route.query.pid }
      await getUpdateDataCompare(params).then((res) => {
        // console.log('update', res)
        this.$message({
              message: res.msg,
              center: true,
              type: 'success',
            })
      })
      
    },
            //缓存第几个父级高亮
       handlFristMenu(index, obj,i) {
            this.linkActive = index;
            this.subLinkActive=index+'-'+i
            // console.log(obj);
            this.resetSetItem('menuItem', this.linkActive);
            sessionStorage.setItem('menuItem', this.linkActive);
            //1.判断是否登录 login: true 需要登录
            // console.log(obj);
            // console.log('token',this.token);
            if (obj.login && !this.token) {
                bus.$emit('handleShowLogin', true);
            } else {
                //2.判断是否需要扣点 power: false 无权限，需要扣点
                // console.log(obj);
                if (!obj.power) {
                    this.refreshPowerStatus(obj.title).then(isPower=>{
                     if (isPower) this.$router.push({ path: obj.url, query: {pid:this.$route.query.pid, companyCountry: this.$route.query.companyCountry ,name: this.$route.query.name} });
                    });
                    
                }else {
                  this.$router.push({ path: obj.url, query: {pid:this.$route.query.pid, companyCountry: this.$route.query.companyCountry ,name: this.$route.query.name} });
                }
            }
            this.powerToPage = JSON.stringify(obj.list);
            console.log('handlFristMenu--', obj);
        },
        refreshPowerStatus(title) {
          
            switch (title) {
             
                case 'Background':
                    this.getOrdersAdd(14);
                    break;
                case 'Roles':
                    this.getOrdersAdd(14);
                    break;
                case 'Relations':
                    this.getOrdersAdd(14);
                    break;
                case 'Partner':
                    this.getOrdersAdd(14);
                    break;
       
                case 'Compare':
                    this.getOrdersAdd(1);
                    break;
                case 'Update':
                    this.getOrdersAdd(2);
                    break;
                case 'Report Error':
                    break;
                case 'Cn_En':
                    break;
                case 'Download Online Report':
                    break;
                case 'Collection':
                    break;
            }
        },
    //获取目录
    async getMenuList() {
    
      let type = '8'
      let User = JSON.parse(sessionStorage.getItem('User'))
      console.log(User);
      this.obj.name=User==null?this.$route.query.name:User.pnameEn
      this.obj.nameCn=User==null?this.$route.query.name:User.pname
      let pid = this.$route.query.pid
      let name = User==null?this.$route.query.name:User.pnameEn

      let res = await getCompanyDetailMenuList('code=' + pid + '&type=' + type)
      if (res && res.code) {
        // console.log('leftmenu', res)
        let list = res.data
        var arrLsit = []
        list.forEach((item, index) => {
          var pobj = {}
          var arr = []
          var powerArr = [];
          if (item.list) {
            item.list.forEach((it, subi) => {
              var obj = {}
              // if (item.title == 'Import & Export') {
              //   obj = {
              //     id: it.code,
              //     name: it.title,
              //     url: '/company/import&export/' + it.title.replace(/\s/g, '').toLowerCase() + '?id3a=' + id3a + '&companyCountry=' + companyCountry,
              //   }
              // } else if (item.title == 'Country Data') {
              //   obj = {
              //     id: it.code,
              //     name: it.title,
              //     url: '/country/' + it.title.replace(/\s/g, '').toLowerCase() + '?id3a=' + id3a + '&companyCountry=' + companyCountry,
              //   }
              // } else {
                obj = {
                  id: it.code,
                  name: it.title,
                  power: it.power,
                  login: it.login,
                  url: '/users/' + it.title.replace(/\s/g, '').toLowerCase() + '?pid=' + pid + '&name=' + name,
                }
              // 
              arr.push(obj)
            })
          }
      
            pobj = {
              login: item.login,
              id: item.code,
              power: item.power,
              title: item.title,
              url: '/users/' + item.title.replace(/\s/g, '').toLowerCase() + '?pid=' + pid + '&name=' + name,
              img: '',//require('@/assets/img/detail/menu_ic' + (index + 1) + '-1.png'),
              img1: '',//require('@/assets/img/detail/menu_ic' + (index + 1) + '-2.png'),
              disBol: true,
              result: arr,
              list: powerArr,
            }
            if (this.$route.name === item.title.replace(/\s/g, '') && !item.power) {
                            this.refreshPowerStatus(item.title);
                        }
       
          arrLsit.push(pobj)
        })

        this.menuTitleList = arrLsit
        !this.subLinkActive ? (this.powerToPag = JSON.stringify(this.menuTitleList[0].list)) : this.powerToPag;
      }
    },
    async getOrdersAdd(type) {
              console.log(type);
            const id3a = this.$route.query.pid;
            const companyCountry = this.companyBase.companyCountry;
            //1  :线上报告
            let params = {
                companyName: this.$route.query.name, //必须
                aaaId: id3a, //必须
                companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            // console.log(params);
            let status=''
            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    this.getMenuList();
                    status=true
                    this.$message({
                        message: data.msg,
                        type: 'success',
                        // duration: 5000,
                    });
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that=this
              setTimeout(function(){
                let routeData = that.$router.resolve({
                path: '/account/addCredit',
            });

            window.open(routeData.href, '_blank');
              },2000)
                    status=false
                }
            });
            return status
        },

        //获取 判断是否要扣点 线上报告,线下报告
        getPDFFile(pdfName) {
            if (pdfName === 'onlineReport') {
                if (!this.powerCurStatus.DownloadOnlineReport) {
                    let type = 14;
                    console.log();
                    this.getOrdersAdd(type).then((res) => {
                      // console.log(res);
                        this.hanldPdfPurchase(pdfName);
                    });
                } else {
                    this.hanldPdfPurchase(pdfName);
                }
            } else {
                if (!this.powerCurStatus.Update) {
                    let type = 2;
                    this.getOrdersAdd(type).then((res) => {
                      if(res){  this.hanldPdfPurchase(pdfName);}
                    });
                } else {
                    this.hanldPdfPurchase(pdfName);
                }
            }
        },
        //1.查找订单；2.下载PDF
        async hanldPdfPurchase(pdfName) {
            let params = 'aaaId=' + this.companyBase.aaaId;
            // console.log(this.companyBase);
            await getPdfPurchase(params).then(async res => {
                if (res && res.code) {
                    // console.log('getPdfPurchase', res);
                    let orderId = '';
                    if (pdfName == 'onlineReport' && res.data.onlineReport) {
                        orderId = res.data.onlineReport.id;
                        // console.log(orderId,'orderId');
                        await getPdfAddress(orderId).then(res => {
                          // console.log(res,'getpdfAddres');
                            if (res && res.code) {
                                window.open(res.data, '_blank');
                            }
                        });
                    } else if (pdfName == 'offlineReport' && res.data.offlineReport) {
                        orderId = res.data.offlineReport.id;
                        this.orderStatus = res.data.offlineReport.orderStatus;
                        await getPdfAddress(orderId).then(res => {
                            if (res && res.code) {
                              
                                this.$message({
                        message: res.msg,
                        type: 'success',
                      
                        
                    });
                    window.open(res.data, '_blank');
                            }
                        });
                    }
                } else {
                    this.$message({
                        message: 'Please purchase PDF',
                        type: 'error',
                        duration: 5000,
                    });
                }
            });
        },
                //compare
        // compareCompanyCountry(name) {
        //     if (!this.powerCurStatus.Compare) {
        //         this.getOrdersAdd(14).then((res) => {
        //             res && name === 'Company' ? this.$router.push({ path: '/company/comparecompanys', query: { id3a: this.companyBase.aaaId, companyCountry: this.companyBase.companyCountry, companyNameEn: this.companyBase.nameEn, companyNameCn: this.companyBase.name } }) : this.$router.push({ path: '/company/comparecountry', query: { id3a: this.companyBase.aaaId, companyCountry: this.companyBase.companyCountry } });
        //         });
        //     } else {
        //         name === 'Company' ? this.$router.push({ path: '/company/comparecompanys', query: { id3a: this.companyBase.aaaId, companyCountry: this.companyBase.companyCountry, companyNameEn: this.companyBase.nameEn, companyNameCn: this.companyBase.name } }) : this.$router.push({ path: '/company/comparecountry', query: { id3a: this.companyBase.aaaId, companyCountry: this.companyBase.companyCountry } });
        //     }
        // },
           //点击Report Error
           detailReportError() {
            this.showReportIndex = true;
            this.powerToReportErrorStatus = this.powerCurStatus.ReportError;
        },
    //关闭report弹窗
    closeDialog(value) {
      this.showReportIndex = value
    },
    closeCollageDialog(value) {
      this.addPopIndex = value[0];
      this.isfalow = value[1];
    },
    //添加列表
    async addToList() {
      const company = JSON.parse(sessionStorage.getItem('company'));
      let collIds=[]
      const params = {
          crefoNo: this.$route.query.pid,
      }
      await getIsCollect(params).then(res=>{
          if(res.code){
            collIds=res.data
          }
      })
      this.collecObj = {
        pnameEn: this.$route.query.name,
        pid: this.$route.query.pid,
        countryCode: this.$route.query.companyCountry,
        collectionIdList:collIds,
        isFavorite:this.isfalow
      };
      this.addPopIndex = true;
    },
    async getCollData(){
      await selectGroup().then(res => {
          if (res && res.code) {
              this.addList = res.data.rows;
          }
          const params = {
              crefoNo: this.$route.query.pid,
          }
          getIsCollect(params).then(res=>{
              if(res.code){
                this.isfalow=true
              }
              else{
                this.isfalow=false
              }
          })
      });
    },

  },
}
</script>
<style scoped>
.table_oper.on {
    background: #FF7600 !important;
    border: 1px solid #FF7600 !important;
}
.table_oper{
  display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border-radius: 13px;
    background: #FFFFFF !important;
    border: 1px solid #DDDDDD !important;
}
.cont_m {
  margin-top: 170px;
  margin-bottom: 80px;
}
.left_menu {
  padding: 20px 20px 20px 20px;
  align-self: flex-start;
}
.de_menu_list .menu_ic {
  height: 20px;
  margin-right: 10px;
}
.de_menu_list .show_ic {
  width: 30px;
  height: 30px;
  text-align: right;
}
.de_menu_list .show_ic img {
  width: 10px;
}
.de_menu_item {
  margin-bottom: 10px;
}
.de_menu_subitem_a,
.de_menu_item_a {
  display: inline-block;
  color: #8497ab;
}
.de_menu_item_a {
  font-size: 16px;
}
.de_menu_item_a .title {
  padding: 10px 10px 10px 10px;
  background-size: 20px 20px;
  background-position: 10px center;
  background-repeat: no-repeat;
}
.de_menu_subitem div {
  padding-left: 31px;
}
.de_menu_subitem_a {
  font-size: 14px;
  padding: 10px;
}
.de_menu_item_a.router-link-exact-active.router-link-active .title,
.de_menu_item_a .subOn {
  font-family: 'Arial Bold';
  color: #022955;
  background-color: #ebf2ff;
  border-radius: 14px;
}
.de_menu_item_a.router-link-exact-active.router-link-active .title,
.de_menu_subitem_a.router-link-exact-active.router-link-active {
  background-color: #ebf2ff;
  border-radius: 14px;
  color: #022955;
}
/* .de_menu_item_a .title.ic_0 {
  background-image: url(../../assets/img/users/bargound.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_0 {
  background-image: url(../../assets/img/users/background.png);
}
.de_menu_item_a .title.ic_1 {
  background-image: url(../../assets/img/users/ro.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_1 {
  background-image: url(../../assets/img/users/roles.png);
}
.de_menu_item_a .title.ic_2 {
  background-image: url(../../assets/img/users/relationsliang.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_2 {
  background-image: url(../../assets/img/users/relation.png);
}
.de_menu_item_a .title.ic_3 {
  background-image: url(../../assets/img/users/aiixnhui.png);
}
.de_menu_item_a .title.ic_3.subOn,
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_3 {
  background-image: url(../../assets/img/users/aixin.png);
} */

.de_menu_sublist {
  will-change: height;
  overflow: hidden;
}
.close,
.open {
  transform-origin: center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
}
.open {
  background-image: url(../../assets/img/search/menu_ic2.png);
}
.close {
  background-image: url(../../assets/img/search/menu_ic1.png);
}
.left_item {
  width: 140px;
  display: flex;
  align-items: center;
  height: 40px;

  border-radius: 14px;

  padding: 10px 10px 8px 10px;
  margin-bottom: 20px;
}
.left_img {
  margin-right: 10px;
}
a {
  color: #8497abff;
}
.routercolor {
  background: #ebf2ff;
  color: #022955ff;
}

.flex_between_box {
  display: flex;
  justify-content: space-between;
}
.c_detail_top_right,
.c_detail_top_left {
  margin-top: 30px;
}
.c_d_title {
  width: 490px;
  height: 74px;
  font-size: 28px;
  font-family: 'Arial Bold';
  line-height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 20px;
}
.c_d_tag {
  width: 400px;
}
.c_d_tag .el-tag {
  height: 26px;
  line-height: 26px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ff7600;
  font-size: 12px;
  color: #ff7600;
  margin: 0 4px 6px;
}
.last_date {
  line-height: 27px;
  font-size: 12px;
  color: #8497ab;
}
.btn_blue {
  width: 140px;
  height: 40px;
  background: #1290c9;
  border-radius: 12px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.btn_blue img {
  width: 18px;
  height: 18px;
  /* margin-right: 6px; */
}
.c_d_buttom {
  margin-bottom: 40px;
}
.c_d_buttom button + button {
  margin-left: 10px;
}
.c_d_icbtn {
  text-align: right;
}
.c_d_icbtn .el-button {
  width: 26px;
  height: 26px;
  background: #022955;
  border-radius: 13px;
  border: none;
  padding: 0;
}
.c_d_icbtn .el-button img {
  width: 14px;
}
.tool_add {
  background: #ff7600 !important;
}
.tool_tip {
  height: 34px;
  font-size: 14px;
  color: #022955;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(10, 33, 57, 0.2);
  border-radius: 8px;
  border: none !important;
}
.el-tooltip__popper[x-placement^='bottom'] {
  margin-top: 4px !important;
}
.tool_tip2 {
  width: 174px;
}
.tool_tip3 {
  width: 110px;
}
.pop_link {
  padding: 0 0 8px;
  border-radius: 8px;
  line-height: 42px;
  font-size: 16px;

  box-shadow: 0px -8px 20px 0px rgba(10, 33, 57, 0.24);
}
.compare_box li {
  text-align: center;
  margin-top: 4px;
}
.compare_box li a {
  color: #022955;
  line-height: 42px;
  font-size: 16px;
}
.compare_box li:hover {
  background: rgba(18, 144, 201, 0.1);
}
.compare_box li:hover a {
  color: #1290c9;
}
.btn_blue:disabled {
    color: #8497ab;
    background: #E7EAED !important;
    cursor: no-drop;
}
.de_menu_item_a.router-link-active .title,
.de_menu_subitem_a.router-link-active {
    background: #ebf2ff;
    border-radius: 14px;
    color: #022955;
}

</style>