<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normal">
            <div class="detail_cont">
                <div class="dialog_detail_title">Report Errors</div>
                <div class="add_list">
                    <el-tabs type="card" class="tab_box" v-model="tabName">
                        <el-tab-pane label="Report my company" name="Reportmycompany" class="report_box">
                            <div class="cont_item" @click="finDialog = true">Update data</div>
                            <div class="cont_item" @click="reportDialog = true">Report information errors</div>
                        </el-tab-pane>
                        <el-tab-pane label="Report others company" name="Reportotherscompany" class="report_box">
                            <div class="cont_item" @click="finDialog = true">Update data</div>
                            <div class="cont_item" @click="reportDialog = true">Report information errors</div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-dialog>
        <!-- 点击 Update data 后弹出 -->
        <el-dialog :visible.sync="finDialog" center :close-on-click-modal="false" custom-class="dialog_lg">
            <div class="detail_cont">
          
                <el-form ref="form" :model="form"  class="form_box" label-position="left">
             
                    <div class="fin_cont_box flex_center_between_box">
                        <div class="fin_modu_box">
                            <div class="fin_modu">
                                <el-form-item label="Name:">
                                    <el-input v-model="form.name"></el-input>
                                </el-form-item>
                                <el-form-item label="Other Name:">
                                    <el-input v-model="form.otherName"></el-input>
                                </el-form-item>
                                <el-form-item label="Gender:">
                                    <el-input v-model="form.gender"></el-input>
                                </el-form-item>
                                <el-form-item label="Age:">
                                    <el-input v-model="form.age"></el-input>
                                </el-form-item>
                                <el-form-item label="Education:">
                                    <el-input v-model="form.education"></el-input>
                                </el-form-item>
                                <el-form-item label="Country:">
                                    <el-input v-model="form.country"></el-input>
                                </el-form-item>
                                     <el-form-item label="Experience:">
                                    <el-input v-model="form.experience"></el-input>
                                </el-form-item>
                                <el-form-item label="Phone:">
                                    <el-input v-model="form.phone"></el-input>
                                </el-form-item>
                                <el-form-item label="Email:">
                                    <el-input v-model="form.email"></el-input>
                                </el-form-item>
                            </div>
                        
                        </div>
      
                    </div>

      
                    <div class="btn_box">
                        <el-button class="btn_export" @click="onSubmit()">Submit</el-button>
                        <el-button class="btn_cancel" @click="cancel()">Cancel</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>

        <!-- 点击 Report information errors 后弹出 -->
        <el-dialog :visible.sync="reportDialog" center :close-on-click-modal="false" custom-class="dialog_normal">
            <div class="detail_cont">
                <div class="dialog_detail_title">Report Errors</div>
                <div class="detail_cont_text">
                    Report Errors allows users to communicate with us to solve any data issues and improve data quality.<br>
                   <p>  1.If you find any data problems or have a question about this data</p>
                  <p>2.If you want to contribute company information to improve this data</p>
                  <p>3.If you choose not to disclose your own company information that you might consider private</p>
                    <p>4.If you have any constructive suggestions for us to improve services</p>
                    All comments submitted are subject to data verification. Users must complete your own user profile as our representative may contact you for authentication and verification of your company any rights to represent your company. 
                </div>
                <div class="rep_list">
                    <el-form ref="reportForm" :model="reportForm" class="report_form">
                        <div class="report_item">
                            <div v-for="(report, index) in addReportList" :key="index">
                                <div class="add_btn" @click="addForReport()" v-show="index == 0"></div>
                                <div class="del_btn" @click="delForReport(index)" v-show="index > 0"></div>
                                <div class="report_form_box">
                                    <el-form-item label="Subject:">
                                        <el-select v-model="report.subject" placeholder="Select" @change="subjectChange(report.subject)">
                                            <el-option :label="item" :value="item" v-for="(item, index) in subjectOptions" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Correct Information:">
                                        <el-input v-model="report.demand" type="textarea" class="limit_box" maxlength="500" @input="inputChange(report.demand)" show-word-limit></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="flex_center_between_box">
                                <el-button class="btn_export" :disabled="disabledReport" @click="onSubmitReport()">Submit</el-button>
                                <el-button class="btn_cancel" @click="cancelReport()">Cancel</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getReportError, getCompanySnap, getUpdateDataCompare, uploadFile, deleteFile } from '@/api/companyApi.js'
import crypto from '../utils/crypto'
export default {
  props: {
    showReport: {
      type: Boolean,
      default: false,
    },
    powerStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // newDialog: true,
      tabName: 'Reportmycompany',
      finDialog: false,
      reportDialog: false,
      form: {
        // year: new Date().getFullYear(),
        name: '',
        otherName: '',
        gender: '',
        age: '',
        education: '',
        country: '',
        experience: '',
        phone: '',
        email: '',
        // coreCompetency: [],
        // businessOutlook: '',
        // uploadFiles: [],
      },
      type: '701',
      filesList: [],
      provideOptions: ['CNY', 'EURO', 'USD'],
      yearOptions:[], //['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008'],
      busOptions: ['Good', 'Not optimistic', 'Unsure'],
      CompetencyList: ['Patent', 'Trademark', 'Copyright', 'Software copyright', 'High-tech'],
      intBusOptions: ['Yes', 'No'],
      subjectOptions: ['Background', 'Roles', 'Relations', 'Partner'],
      disabledReport1: true,
      disabledReport2: true,
      disabledReport: true,
      reportForm: { subject: '', demand: '' },
      addReportList: [{ subject: '', demand: '' }],
    }
  },
  computed: {
    newDialog: {
      get() {
        return this.showReport
      },
      set(newValue) {},
    },
  },
  created() {
    this.getYears()
  },
  methods: {
    async setData() {
      const id3a = this.$route.query.pid

      let companyName = this.$route.query.name
      const params = {
            companyName: companyName,
            companyId: id3a,
            demand: JSON.stringify(this.form),
            type: this.type,
          }
          let res = await getUpdateDataCompare(params)
          if (res && res.code) {
            this.$message({
              message: res.msg,
              center: true,
              type: 'success',
            })
          }
    },
    //获取年 2008-2023
    getYears() {
      const loadYear = new Date().getFullYear()
      const n=loadYear-2007
      for (var i = 0; i < n; i++) {
        const year = loadYear - i
        this.yearOptions.push(year)
      }
    },
    //关闭弹窗
    closeDialog() {
      this.$emit('closeReportDialog', !this.newDialog)
    },
    //删除文件
    handleRemove(file, fileList) {
     let index=fileList.findIndex(item => item.name == file.name)

      const str = this.form.uploadFiles[index]
      let i = str.lastIndexOf('/')
      let result = str.substr(i + 1, str.length)

     let params={'fileName':result}
      deleteFile(params).then((res) => {
        if (res && res.code) {
          this.$message({
            message: res.msg,
            center: true,
            type: 'success',
          })
        }
      })
    },
    //上传文件
    hanldUploadFile(params) {
      let file = params.file
      let form = new FormData()
      form.append('file', file)
      uploadFile(form).then((res) => {
        if (res && res.code) {
          this.form.uploadFiles.push(res.data)
        }
      })
    },
    //提交update
    onSubmit() {
      if(!this.powerStatus){
        // let type=14
        // this.$parent.getOrdersAdd(type).then((res)=>{
        //   if(res){
        //     this.newDialog = false
        //     this.finDialog = false
            this.setData()
        //   }
        // })
      }
      else{
        this.newDialog = false
        this.finDialog = false
        this.setData()
      }
    },
    //取消update
    cancel() {
      this.finDialog = false
    },

    subjectChange(val) {
      val.length > 0 ? (this.disabledReport1 = false) : (this.disabledReport1 = true)
      if (this.disabledReport1 == false && this.disabledReport2 == false) {
        this.disabledReport = false
      } else {
        this.disabledReport = true
      }
    },
    inputChange(val) {
      val.length > 0 ? (this.disabledReport2 = false) : (this.disabledReport2 = true)
      if (this.disabledReport1 == false && this.disabledReport2 == false) {
        this.disabledReport = false
      } else {
        this.disabledReport = true
      }
    },
    //提交report
    onSubmitReport() {
      if(!this.powerStatus){
        // let type=14
        // this.$parent.getOrdersAdd(type).then((res)=>{
        //   if(res){
            this.hanldSubmitReport()
        //   }
        // })
      }
      else{
        this.hanldSubmitReport()
      }
    },
   async hanldSubmitReport(){
      this.newDialog = false
      this.reportDialog = false
   
      const name=this.$route.query.name
      const id3a = this.$route.query.pid
      const companyCountry = this.$route.query.companyCountry
      let companyName = ''
   
        //解密

     
          let list = this.addReportList
       
          let arr = []
          list.forEach((item) => {
            let obj = {}
            obj = {
              coId: '',
              companyId: id3a,
              companyName:name,
              subject: item.subject,
              demand: item.demand,
              type: '601',
            }
            arr.push(obj)
          })
          let res = await getReportError(arr)
          if (res && res.code) {
            this.$message({
              message: res.msg,
              center: true,
              type: 'success',
            })
          }
      
    },
    //取消report
    cancelReport() {
      this.reportDialog = false
    },
    //添加report表单
    addForReport() {
      const obj = { subject: '', demand: '' }
      this.addReportList.push(obj)
      this.disabledReport = true
      this.disabledReport1 = true
      this.disabledReport2 = true
    },
    //删除report表单
    delForReport(n) {
      this.addReportList.splice(n, 1)
    },
  }
}
</script>
<style scoped>
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
    padding: 11px 16px;
}
.result_tab {
    padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 28px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 2px 28px;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 160px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}

.tab_box /deep/.el-tabs__content {
    width: 440px;
    height: 130px;
    padding: 10px 0;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.cont_item {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 12px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
    background: #f2f5f9;
    background-image: url(../assets/img/detail/btn_ic9.png);
    background-repeat: no-repeat;
    background-size: 8px 14px;
    background-position: 95% center;
    cursor: pointer;
}

.fin_title {
    font-family: 'Arial Bold';
    margin-bottom: 20px;
    line-height: 24px;
    font-size: 20px;
    color: #022955;
}
.form_box /deep/ .el-input__inner {
    width: 162px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.fin_top_sel /deep/ .el-input__inner {
    width: 276px;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    font-size: 12px;
}
.fin_top_year /deep/.el-form-item__label,
.fin_top_item /deep/.el-form-item__label {
    width: auto !important;
}
.form_box /deep/.el-form-item__label {
    padding-right: 10px;
}
.fin_top_item /deep/ .el-form-item__content {
    margin-left: 174px !important;
}
.fin_top_year /deep/ .el-form-item__content {
    margin-left: 50px !important;
}
.fin_top_year {
    margin-left: 40px;
}
.form_box /deep/ .el-form-item__label {
    color: #022955 !important;
}
.form_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab !important;
}
.fin_cont_box {
    margin-bottom: 10px;
}
.fin_modu {
    /* width: 350px;
    height: 320px; */
    padding: 20px 10px;
    margin-bottom: 20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.fin_modu.last {
    height: 661px;
}
.form_box /deep/ .el-form-item__content {
    line-height: 34px;
    margin-left: 132px;
}
.form_box /deep/ .el-form-item__label {
    display: flex;
    align-items: center;
    height: 37px;
    line-height: 16px;
}

.form_box /deep/ .el-form-item {
    margin-bottom: 12px;
    display: inline-block;
    margin-right: 60px;
    margin-bottom: 12px;
}
.bus_box /deep/ .el-textarea__inner,
.bus_box /deep/ .el-input__inner {
    width: 928px;
    background: #f2f5f9;
}
.bus_box2 /deep/ .el-form-item__content {
    display: flex;
}
.out_item /deep/ .el-input__inner,
.bus_box2 /deep/ .el-input__inner {
    width: 220px;
    height: 34px;
    background: #f2f5f9;
}
.bus_box2 /deep/ .m_l .el-input__inner {
    margin-left: 16px;
}
.form_box /deep/ .out_item {
    margin-bottom: 20px;
}
.form_box /deep/ .el-input__icon {
    height: auto;
}
.upload-demo /deep/ .el-button {
    width: 90px;
    height: 34px;
    padding: 8px 20px;
    background: #edf9ff;
    border-radius: 12px;
    border: 1px solid #1290c9;
    color: #1290c9;
    font-size: 14px;
}
.check_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #8497ab;
}
.report_form /deep/ .el-form-item__label,
.check_box /deep/ .el-checkbox__label {
    font-size: 14px;
    color: #022955;
}
.check_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.check_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1290c9;
    border-color: #1290c9;
}
.check_box /deep/ .el-checkbox__inner::after {
    height: 12px;
    left: 5px;
    top: 0px;
    width: 6px;
}
.btn_box {
    text-align: right;
    margin-top: 24px;
    margin-bottom: 16px;
}
.btn_box button + button {
    margin-left: 40px;
}
.rep_list {
    padding-bottom: 10px;
}

.add_btn {
    background: #1290c9;
    background-image: url(../assets/img/detail/add_ic.png);
}

.del_btn {
    background: #f56b6b;
    background-image: url(../assets/img/detail/del_ic.png);
}
.del_btn,
.add_btn {
    float: right;
    width: 26px;
    height: 26px;
    margin-top: -32px;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
}
.report_form_box {
    padding: 10px 10px 0;
    margin-top: 42px;
    margin-bottom: 20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.report_form /deep/ .el-input__inner {
    width: 420px;
    height: 34px;
    line-height: 34px;
}
.report_form /deep/ .el-form-item {
    margin-bottom: 20px;
}
.limit_box /deep/ .el-textarea__inner {
    height: 150px;
}
.report_item {
    margin-top: 48px;
}
.detail_cont_text{
    /* 解决英文截断问题 */
    word-break: keep-all;
    text-align: justify;
    font-size: 14px;
    color: #022955;
    line-height: 19px;
}
.detail_cont_text p{
    padding-left: 10px;
}
@media (max-width: 821px) {
  .report_form /deep/ .el-input__inner {
    width: 300px !important;
}
}

</style>

